import { fonts, APPBAR_HEIGHT_UNITS, theme, mySeequentFonts } from '@local/web-design-system';
import { Theme } from '@mui/material';
import {
    MakeStylesParams,
    MuiStyleOverridesParams,
    UseStylesReturnType,
} from 'src/components/commonUI.types';
import { textStyles } from 'src/components/Configuration/Logging/Logging.styles';
import { PROJECT_THUMBNAIL_RATIO } from 'state-domains/constants';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const commonStyles = (theme: Theme) =>
    ({
        root: {
            display: 'flex',
            minHeight: '100vh',
            backgroundColor: theme.palette.grey[100],
        },
        content: {
            width: '100%',
            backgroundColor: theme.palette.grey[100],
            marginTop: theme.spacing(APPBAR_HEIGHT_UNITS),
            minWidth: 0,
        },
        contentWhite: {
            backgroundColor: theme.palette.common.white,
        },
        appContentAreaRoot: {
            backgroundColor: theme.palette.grey[100],
        },
        contentTitle: {
            ...fonts.h2,
            color: theme.palette.primary.main,
        },
        fullHeight: {
            height: '100%',
        },
        leftIcon: {
            marginRight: theme.spacing(),
        },
        row: {
            flexBasis: 0,
            flexGrow: 0,
        },
        rowGrow: {
            flexBasis: 0,
            flexGrow: 1,
            display: 'flex',
        },
        buttonIconText: {
            paddingLeft: theme.spacing(2),
        },
        emptyStateFullPage: {
            padding: `${theme.spacing(12.5)} 0 0 0`,
        },
        emptyStateTable: {
            padding: `${theme.spacing(2.5)} 0`,
        },
        emptyStateTableCell: {
            borderBottom: 0,
        },
        deleteSecondaryButton: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[50],
        },
        drawerOpen: {
            width: theme.spacing(25),
        },
        overlayDialog: {
            zIndex: 1,
            position: 'absolute',
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
        },
        overlayBackground: {
            backgroundColor: 'white',
            boxShadow: '0 0 6px 0 rgb(0 0 0 / 10%)',
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        helperText: {
            paddingTop: '2px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            lineHeight: '13px',
            color: theme.palette.error.main,
            ...fonts.errorMessage,
        },
        changeHover: {
            '&:hover': {
                backgroundColor: '#265C7F',
            },
        },
        primaryText: {
            wordBreak: 'break-word',
            paddingRight: theme.spacing(1),
            fontSize: '12px',
            textDecoration: 'none',
            display: 'flex',
            color: '#000',
        },
        secondaryText: {
            fontSize: '11px',
            color: '#8e9095',
            wordWrap: 'break-word',
        },
        emptyStateImg: {
            position: 'relative !important',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            overflow: 'hidden !important',
        } as unknown as CSSObject,
        emptyStateImgBody: {
            position: 'absolute !important',
            top: '55% !important',
            left: '50% !important',
            transform: 'translate(-50%, 0) !important',
            paddingLeft: '24px !important',
            width: '100% !important',
            maxWidth: '100% !important',
            flexBasis: '100% !important',
        } as unknown as CSSObject,
        emptyStateTitle: {
            fontSize: '14px !important',
            paddingBottom: '0px !important',
        } as unknown as CSSObject,
        emptyStateMessage: {
            fontSize: '11px !important',
            paddingTop: '2px !important',
        } as unknown as CSSObject,
        contentTitleUnderline: {
            position: 'relative',
            color: '#265C7F',
            fontFamily: 'Roboto',
            fontSize: '17px',
            letterSpacing: '0.2px',
            lineHeight: '20px',
            width: '100%',
            textAlign: 'left',
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: '400 !important',
        },
        paginationButton: {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.grey[700],
            borderRadius: 0,
            padding: 0,
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        typographyRequired: {
            '&::after': {
                content: "' *'",
                color: '#D0021B',
            },
        },
        notificationBorder: {
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        notificationMessage: {
            color: '#39393C',
            fontFamily: 'Roboto',
            fontSize: '11px',
            lineHeight: '15px',
        },
        chipStyles: {
            ...mySeequentFonts.chip,
            marginLeft: '8px',
            height: '18px',
            textTransform: 'uppercase' as const,
            color: 'white',
            letterSpacing: 'normal',
            lineHeight: '18px',
        },
        thinScrollBar: {
            scrollbarWidth: 'thin',
            overflowY: 'auto',
        },
        actionText: {
            paddingLeft: theme.spacing(2),
            width: '220px',
            height: '44px',
            textWrap: 'nowrap',
            textAlign: 'start',
        },
        cardBorder: {
            display: 'flex',
            minHeight: '48px',
            borderRadius: '3px',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            flexWrap: 'nowrap' as const,
            marginTop: '8px',
            cursor: 'pointer',
            minWidth: '400px',
            position: 'relative' as const,
            zIndex: 1,
        },
        fontStyles: {
            color: '#3D3D3D',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '1.5',
            fontWeight: 400,
            letterSpacing: '0.1px',
            margin: 0,
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical' as const,
            WebkitLineClamp: '2',
            height: '100%',
            padding: '0px 16px',
            wordBreak: 'break-word' as const,
        },
        textFieldStylesImportant: {
            width: '100%',
            height: '100%',
            '& .MuiInput-input-root': {
                color: '#3D3D3D !important',
                whiteSpace: 'pre-wrap !important',
                fontFamily: 'Roboto !important',
                fontStyle: 'normal !important',
                fontSize: '14px !important',
                lineHeight: '1.5 !important',
                fontWeight: '400 !important',
                letterSpacing: '0.1px !important',
            },
        },
        subtitleText: {
            fontWeight: 300,
            fontSize: '12px !important',
            color: '#333333 !important',
            WebkitLineClamp: '1',
        },
    }) as const;
export const mainFontTypes = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    letterSpacing: '0.1px',
    lineHeight: '17px',
};
export const mainFonts = {
    ...mainFontTypes,
    color: '#1D1F23',
};
export const mainTextFonts = {
    ...mainFonts,
    fontWeight: fonts.weights.regular,
    fontSize: 14,
};
export const mainFieldStyle = {
    height: '40px',
    secondaryHeight: '48px',
    paddingLeft: '16px',
    fontSize: mainTextFonts.fontSize,
    letterSpacing: '0.1px',
    placeHolder: {
        color: 'rgba(0, 0, 0, 0.42)',
        fontStyle: 'italic',
        fontWeight: '400',
        fontSize: mainTextFonts.fontSize,
        lineHeight: '48px',
    },
    dropDown: {
        fontSize: '13px',
    },
};
export const alertDialogTitle = {
    fontWeight: `${fonts.weights.medium} !important`,
};

export const cardHeaderProperties = {
    background: theme.palette.primary.main,
    height: theme.spacing(7),
    '& .MuiCardHeader-title': {
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 'normal',
    },
    '& .MuiCardHeader-subheader': {
        color: theme.palette.common.white,
        opacity: '0.7',
        fontSize: '11px',
        fontWeight: 'normal',
    },
    '& .MuiCardHeader-action': {
        marginRight: '0px',
        marginTop: '0px',
    },
};

export const dataGridStyles = (padding = 1) => ({
    // #region ================================ AG Grid CSS Variables ================================
    '--ag-cell-horizontal-padding': theme.spacing(padding),
    '--ag-cell-widget-spacing': theme.spacing(padding),
    '--ag-header-background-color': theme.palette.grey[800],
    '--ag-header-foreground-color': theme.palette.common.white,
    '--ag-material-primary-color': theme.palette.primary.main,
    '--ag-material-accent-color': theme.palette.primary.main,
    '--ag-header-cell-hover-background-color': theme.palette.grey[500],
    '--ag-checkbox-checked-color': theme.palette.primary.main,
    '--ag-cell-horizontal-border': `1px solid ${theme.palette.grey[200]}`,
    '--ag-borders': `1px solid`,
    '--ag-border-color': theme.palette.grey[200],
    '--ag-header-column-separator-display': 'block',
    '--ag-header-column-separator-height': '100%',
    '--ag-header-column-separator-width': '1px',
    '--ag-header-column-separator-color': theme.palette.common.white,
    '--ag-font-family': '"Roboto", sans-serif',
    '--ag-header-cell-moving-background-color': theme.palette.grey[800],
    '--ag-font-size': '13px',
    // #endregion

    // #region ================================ Class Overrides ================================
    // Background color styling for side panel.
    '.ag-side-bar': {
        backgroundColor: `${theme.palette.grey[100]}`,
    },

    '.ag-cell': {
        paddingLeft: 'var(--ag-cell-horizontal-padding)',
        paddingRight: 'var(--ag-cell-horizontal-padding)',
    },

    '.ag-ltr .ag-row-drag': {
        marginRight: 0,
        width: '100%',
        justifyContent: 'center',
    },

    '.ag-filter-toolpanel-instance-filter': {
        marginTop: 0,
    },

    '.ag-header-cell-text': {
        display: '-webkit-box',
        whiteSpace: 'pre-wrap' as const,
        WebkitBoxOrient: 'vertical' as const,
        WebkitLineClamp: 2,
        textWrap: 'wrap' as const,
        wordWrap: 'break-word' as const,
        fontSize: 'var(--ag-font-size)',
    },

    '.ag-theme-material .ag-cell-inline-editing': {
        height: '100%',
    },

    '.ag-theme-material input[class^=ag-][type=text]': {
        paddingBottom: 0,
    },

    '.ag-side-buttons': {
        backgroundColor: `${theme.palette.grey[50]}`,
        // border: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: '10px',
    },

    // Need to override changes made by AG grid CSS variables to tool panel.
    '.ag-theme-material .ag-filter-toolpanel-header': {
        color: 'var(--ag-data-color)',
        opacity: 0.7,
    },
    // #endregion

    // #region ================================ Cell classes ================================
    '.limitedPadding': {
        padding: `0 ${theme.spacing(2)} 0 0`,
    },
    '.noHeaderPadding': {
        padding: 0,
    },
    // #endregion
});

export const spanChipStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#FFF',
    borderRadius: '16px',
    cursor: 'unset',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '3px',
    paddingBottom: '3px',
};

export const pAsTypograhpyFontStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    letterSpacing: '0.1px',
    margin: '0px',
};
export const buttonBaseStyles = () => ({
    backgroundColor: theme.palette.grey[50],
    borderColor: '#f5f5f5 !important',
    fontSize: '12px !important',
    '&:hover': {
        backgroundColor: '#e0e0e0',
        color: '#3f5c7d',
        borderColor: '#e0e0e0',
        border: '1px solid',
    },
    '& .MuiButton-endIcon': {
        marginLeft: '10px',
    },
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
        fontSize: '12px !important',
    },
    height: theme.spacing(5),
    width: '168px',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(0.6),
    color: '#000000',
    padding: '0px !important',
});

export const classStyles = (theme: Theme) => {
    const fieldLabelProps = {
        ...mainFonts,
        fontSize: '14px',
        fontWeight: fonts.weights.medium,
    };

    return {
        emptyStateRoot: {
            ...commonStyles(theme).emptyStateTableCell,
        },
        tableList: {
            verticalAlign: 'center',
            padding: theme.spacing(0),
        },

        propertyTable: {
            ...commonStyles(theme).thinScrollBar,
            paddingLeft: `${theme.spacing(4)} !important`,
        },

        listDetails: {
            border: theme.spacing(0),
        },
        buttonWrapper: {
            paddingTop: theme.spacing(2),
        },
        dialogOpen: {
            color: '#265C7F',
        },
        upIconColor: {
            color: '#005E82 !important',
        },

        deleteText: {
            paddingLeft: theme.spacing(2),
            width: '100%',
        },

        tableDetails: {
            border: theme.spacing(0),
        },

        emptyPropertyTable: {
            margin: '13px 3px 0px 4px',
            backgroundColor: theme.palette.grey[50],
            width: '256px',
            height: '144px',
        },
        emptyPropertyTableLabel: {
            margin: `20px ${theme.spacing(4)} 0px ${theme.spacing(4)}`,
            color: '#39393C',
            fontFamily: 'Roboto',
            fontSize: '14px',
            lineHeight: '23px',
            width: '192px',
            textAlign: 'center',
        },
        dropDownList: {
            ...commonStyles(theme).thinScrollBar,
            padding: '0px',
            bgcolor: 'background.paper',
            maxHeight: '519px',
            border: '1px solid rgba(151, 151, 151, 0.9)',
            paddingLeft: theme.spacing(0),
        },
        cardContainer: {
            height: theme.spacing(20),
            marginBottom: theme.spacing(2),
        },
        titleContainer: {
            backgroundColor: '#265C7F',
            width: '100%',
            height: theme.spacing(6),
            display: 'flex',
        },
        title: {
            position: 'relative' as const,
            color: '#FFFFFF',
            fontFamily: 'Roboto',
            fontSize: '17px',
            letterSpacing: '0.2px',
            lineHeight: '20px',
            width: '100%',
            textAlign: 'left',
            marginLeft: '24px',
            alignSelf: 'center',
        },
        fieldLabel: {
            ...fieldLabelProps,
        },
        fieldLabelWithPadding: {
            ...fieldLabelProps,
            paddingTop: '16px',
        },
        checkboxLabel: {
            ...fieldLabelProps,
            fontWeight: fonts.weights.regular,
        },
        fieldLabelSubText: {
            ...fieldLabelProps,
            fontWeight: 400,
            fontSize: '12px',
        },
        newListButton: {
            height: theme.spacing(5),
        },
        textField: {
            height: theme.spacing(5),
        },
        errorPanel: {
            backgroundColor: theme.palette.grey[50],
            width: '100%',
            marginTop: theme.spacing(-1),
        },
        notification: {
            width: theme.spacing(30),
        },
        notificationMessage: {
            color: '#39393C',
            fontFamily: 'Roboto',
            fontSize: '11px',
            lineHeight: '15px',
        },
        border: {
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        progressBar: {
            barColorPrimary: theme.palette.common.white,
            colorPrimary: theme.palette.primary.main,
            width: '100%',
            height: theme.spacing(1),
        },
        innerText: {
            fontSize: '13px',
        },
        typographyRequired: {
            '&::after': {
                content: "' *'",
                color: '#D0021B',
            },
        },
        checkbox: {
            padding: '8px',
        },
        uploadModalRoot: {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        titleText: {
            paddingBottom: '4px',
        },
        fullSizeImage: {
            width: '100% !important',
            height: '100% !important',
            aspectRatio: `${PROJECT_THUMBNAIL_RATIO.WIDTH}/${PROJECT_THUMBNAIL_RATIO.HEIGHT}`,
        },
        emptyStateImg: {
            ...commonStyles(theme).emptyStateImg,
        },
        emptyStateImgBody: {
            ...commonStyles(theme).emptyStateImgBody,
        },
        emptyStateTitle: {
            ...commonStyles(theme).emptyStateTitle,
        },
        emptyStateMessage: {
            ...commonStyles(theme).emptyStateMessage,
        },
        typographyStyles: {
            ...mainTextFonts,
            fontSize: '14px',
            fontWeight: '500',
        },
    } as any;
};

export const useStyles = makeStyles()(classStyles) as (
    params?: MakeStylesParams,
    muiStyleOverridesParams?: MuiStyleOverridesParams,
) => UseStylesReturnType;
export const AccordionStyles = makeStyles()(
    (theme: Theme) =>
        ({
            textField: {
                '.MuiInput-root': {
                    ...textStyles,
                },
                flex: 1,
            },
            iconContainer: {
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginLeft: theme.spacing(1),
                width: '200px',
            },
            typographyContainer: {
                ...textStyles,
            },
            headerContainer: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row-reverse' as const,
                paddingBottom: '8px',
            },
            accordion: {
                boxShadow: 'none !important',
                '& .MuiAccordionSummary-root.Mui-expanded': {
                    minHeight: theme.spacing(4),
                },
                padding: '0px',
                width: '100%',
                minWidth: '432px',
            },
            accordionSummary: {
                flexDirection: 'row-reverse',
                backgroundColor: 'transparent !important',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'translateY(4px) rotate(90deg)',
                },
                padding: '0',
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                margin: '0px',
                minHeight: theme.spacing(4),
                maxHeight: theme.spacing(5),
            },
            accordionSummaryTypography: {
                ...textStyles,
                paddingLeft: '8px',
            },
            accordionDetails: {
                padding: `${theme.spacing(1)} 0px 0px 0px`,
            },
            accordionDetailsHidden: {
                display: 'none',
            },
            arrow: {
                fontSize: theme.spacing(1),
                color: 'inherit',
                width: theme.spacing(1.5),
                height: theme.spacing(1.5),
                marginRight: theme.spacing(1),
            },
            actionButton: {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontSize: '13px',
                lineHeight: '15.23px',
                fontWeight: 400,
                color: '#265C7F',
            },
        }) as const,
);
